import { useQuery } from '@tanstack/react-query';
import { requestCo2Statistics, requestCo2StatisticsWeek } from 'api/co2';
import useFarmStore from 'store/farmStore';

export const CO2_QUERY_KEY = 'co2';
export const CO2_WEEK_QUERY_KEY = 'co2_week';
export const useCo2StatisticQuery = (controllerNumber: number) => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  return useQuery({
    queryKey: [CO2_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestCo2Statistics({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 12 * 60 * 60 * 1000, //12시간
    refetchInterval: 12 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};

export const useCo2StatisticWeekQuery = (controllerNumber: number) => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();

  return useQuery({
    queryKey: [CO2_WEEK_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestCo2StatisticsWeek({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 12 * 60 * 60 * 1000, //12시간
    refetchInterval: 12 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};
