import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Initializing from 'components/fallback/Initializing';
import { useState, useEffect, Suspense } from 'react';
import Routing from 'Routing';
import { auth } from 'setting/fb';
import { reactQueryOption } from 'setting/reactQueryOption';
import useLoginStore from 'store/loginStore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useLocaleStore from 'store/localeStore';
import clsx from 'clsx';
import { isLocale } from 'types/types';
// 구글 유저의 존재 여부 확인 및 로그인 체크

/**React query */
const queryClient = new QueryClient({
  defaultOptions: reactQueryOption,
});

function App() {
  const { setIsGoogleUser, setEmail } = useLoginStore();
  const [userName, setUserName] = useState<string>('');

  const { locale, isEnglish, isVietnamese, setLocale } = useLocaleStore(); // 현재 언어 설정 가져오기

  useEffect(() => {
    switch (locale) {
      case 'ENG':
        document.title = 'InsectHub';
        break;
      case 'KR':
        document.title = '인섹트허브';
        break;
      // 다른 언어 설정에 대한 케이스 추가
      default:
        document.title = 'InsectHub';
    }
  }, [locale]); // locale 값이 변경될 때마다 실행

  // 언어 설정을 가져오는 이펙트
  useEffect(() => {
    // URLSearchParams를 사용하여 쿼리 파라미터에서 언어 설정을 가져옴
    const params = new URLSearchParams(window.location.search);
    const language = params.get('lang');
    if (!language) return; // 언어 설정이 없으면 기본값으로 한국어 설정
    const locale = language.toUpperCase();
    if (isLocale(locale)) {
      setLocale(locale);
    }
  }, [setLocale]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.email && setEmail(user.email);
        user.displayName && setUserName(user.displayName);
        setIsGoogleUser(true);
      } else {
        setIsGoogleUser(false);
      }
    });
  }, [setEmail, setIsGoogleUser]);

  return (
    <div
      className={clsx({
        'font-noto': !isVietnamese,
        '-tracking-[0.05em]': isEnglish,
        '-tracking-[0.07em] font-openSans': isVietnamese,
      })}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Initializing />}>
          <Routing userName={userName} />
          <ReactQueryDevtools initialIsOpen={false} />
        </Suspense>
      </QueryClientProvider>
    </div>
  );
}

export default App;
