import { Access, HumidityByDay, HumidityByHour } from 'types/types';
import customAxios from './customAxios';
import { getApiBaseUrl } from 'api';

// HumidityStatisticsResponse 타입 정의
type HumidityStatisticsResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  humidity_statistics?: HumidityByHour[]; // 시간별 습도 통계 정보
};

// HumidityStatisticsWeekResponse 타입 정의
type HumidityStatisticsWeekResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  humidity_statistics?: HumidityByDay[]; // 일간 습도 통계 정보
};

type HumidityStatisticsPayload = {
  farmNumber: number;
  controllerNumber: number;
};
// 습도 통계 정보를 요청하는 함수
export async function requestHumidityStatistics({
  payload,
  access,
}: {
  payload: HumidityStatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response = await customAxios.post<HumidityStatisticsResponse>(
      `${getApiBaseUrl(access)}/web/humidity_statistics.php`,
      requestBody,
    );
    return response.data.humidity_statistics; // 습도 통계 정보 반환
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
  }
}

// 주간 습도 통계 정보를 요청하는 함수
export async function requestHumidityStatisticsWeek({
  payload,
  access,
}: {
  payload: HumidityStatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response = await customAxios.post<HumidityStatisticsWeekResponse>(
      `${getApiBaseUrl(access)}/web/humidity_statistics_week.php`,
      requestBody,
    );
    return response.data.humidity_statistics; // 주간 습도 통계 정보 반환
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
  }
}
