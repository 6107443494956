export type ControllerList = number[];
export type Access = 0 | 1 | 2;
export type AccessMap = Record<number, Access>; //농장 번호에 따른 서버 번호

export type Auth = 0 | 1 | 2 | 3 | 4; //권한 (0: Guest, 1: Manager, 2: Owner, 3: Admin, 4: Master)
export type Language = 1 | 2 | 3; //1:한국어, 2:영어, 3:베트남어
export type WebData = {
  selected_id: number[];
  language: Language;
  access: Access | Access[];
  auth: Auth;
  controller_number: ControllerList[];
};

export type FarmControllerListMap = Record<number, ControllerList>;
export type UserTypeMap = Record<number, UserType>;

export type Controller = {
  co2: number;
  co2_target: number;
  co2_range: number;
  datetime: string;
  feed_time: number;
  humidity: number;
  humidity_range: number;
  humidity_target: number;
  is_auto: number;
  operatingtime: number;
  pm25: number;
  spray_time: number;
  temperature: number;
  temperature_range: number;
  temperature_target: number;
  vent_time: number;
  voc: number;
  water_spray_term: number;
  vent_term: number;
  feed_term: number;
  start_hour: number;
  light_time: number;
  night_time: number;
};

// 밤 시간 대에 컨트롤러 환경 설정값
export type ControllerNight = {
  temperature_target: number;
  humidity_target: number;
  co2_target: number;
  temperature_range: number;
  humidity_range: number;
  co2_range: number;
  spray_target: number;
  vent_target: number;
  feed_target: number;
  light_target: number;
  water_spray_term: number;
  vent_term: number;
  feed_term: number;
};

export type RelayType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type Relay = [
  relayNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  relayType: RelayType,
  relayStatus: 0 | 1,
];
export type RelaySettingItem = {
  relay: Relay;
};
export type RelaySetting = RelaySettingItem[];
export type TemperatureByHour = {
  time: number;
  avg_temperature: number;
  datetime: string;
};

export type TemperatureByDay = {
  day: number;
  avg_temperature: number;
  datetime: string;
};

export type HumidityByHour = {
  time: number;
  avg_humidity: number;
  datetime: string;
};

export type HumidityByDay = {
  day: number;
  avg_humidity: number;
  datetime: string;
};

export type Co2ByHour = {
  time: number;
  avg_co2: number;
  datetime: string;
};

export type Co2ByDay = {
  day: number;
  avg_co2: number;
  datetime: string;
};

export type Device = {
  cell_number: number;
  farm_number: number;
  auto_flag: number; //0: 수동, 1: 자동
  level1_day: number;
  level2_day: number;
  level3_day: number;
  level4_day: number;
  level5_day: number;
  level6_day: number;
  level7_day: number;
  level1_water: number;
  level2_water: number;
  level3_water: number;
  level4_water: number;
  level5_water: number;
  level6_water: number;
  level7_water: number;
  level8_water: number;
  level1_light: number;
  level2_light: number;
  level3_light: number;
  level4_light: number;
  level5_light: number;
  level6_light: number;
  level7_light: number;
  level8_light: number;
  level1_color: number;
  level2_color: number;
  level3_color: number;
  level4_color: number;
  level5_color: number;
  level6_color: number;
  level7_color: number;
  level8_color: number;
  water_spray_term: number;
  start_hour: number;
  description: number;
  cell_type?: CellType;
  harvest_level: number;
  egg_harvest_level: number;
};

export type Level = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type UserType = number; //twinstarfarm + vandalhub | twinstarfarm|vandalhub| x
const CELL_TYPE_VALUES = [1, 2, 3, 4, 5] as const;
export type CellType = (typeof CELL_TYPE_VALUES)[number];

export function isCellType(value: any): value is CellType {
  return CELL_TYPE_VALUES.includes(value);
}

/**
 * 레벨별 환경설정
 */
export type LevelSettings = {
  level: Level; // The level number.
  lightTime: number; // The duration of light exposure in minutes.
  waterTime: number; // The duration of water supply in minutes.
  color: number; // The color associated with the level.
};
export type LevelSettingsArray = LevelSettings[];

export type Cell = {
  cell_number: number;
  farm_number: number;
  line_number: number;
  block_number: number;
  floor_number: number;
  cell_type: CellType;
  temperature: number;
  humidity: number;
  brightness: number;
  startdate: string;
  days: number;
  operatingtime: number;
  reset_count: number;
  datetime: string;
  status: number;
  weight: number;
  force_update: number;
  description: string;
};
export type CellStatus = {
  rate: number; //사육칸의 상태 (default 0)
  partialHarvest: number; //중간에 일부만 채집된 생물의 마리수
  note: string; //특이사항
};
export type CellDetail = Cell & CellStatus;
export type CellStatusHarvestLog = CellStatus & {
  harvest_number: number;
  cell_number: number;
  datetime: string;
};

export type Incubator = {
  incu_number: number; //부화실 번호
  farm_number: number; //농장 번호
  block_number: number; //블록(칸) 번호
  floor_number: number; //층 번호
  cell_type: `${CellType}`; //곤충 종류
  temperature: number; //온도
  humidity: number; //습도
  startdate: string; //입식 시간
  days: number; //경과일
  datetime: string; //갱신 시각
  status: number; //상태 (0:stop, 1:run, 2:empty)
  description: string; //특이사항
};

export type IncubatorStatus = 0 | 1 | 2;
/**언어설정*/
export type Locale = 'KR' | 'ENG' | 'VNM'; //한국어, 영어, 베트남어
export function isLocale(value: any): value is Locale {
  return ['KR', 'ENG', 'VNM'].includes(value);
}
export type Harvest = {
  harvest_number: number;
  line_number: number;
  block_number: number;
  floor_number: number;
  cell_number: number;
  cell_type: CellType;
  date: string;
  days: number;
  weight_live: number;
  weight_dry: number;
  description: string;
  level?: number;
};

export type EggHarvest = {
  harvest_number: number;
  line_number: number;
  block_number: number;
  floor_number: number;
  cell_number: number;
  cell_type: CellType;
  date: string;
  days: number;
  description: string;
};

export type TodoList = {
  farm_number: string;
  list1: string;
  list2: string;
  list3: string;
  list4: string;
  list5: string;
  list6: string;
  list7: string;
  list8: string;
  list9: string;
  list10: string;
  dateTime: string; //"2020-04-24 00:00:00"
  description: string;
};

export type Farm = {
  id: number;
  country: string;
  farm_name: string;
  farm_address: string;
  line_count: number;
  line_block: number;
  block_count: number;
  incu_floor_count: number;
  incu_block_count: number;
  temperature: number;
  humidity: number;
  description: string;
};

export type FarmMember = {
  user: string;
  email: string;
  auth: Auth;
  language: Locale;
};

export const CHECK_LIST_TYPES = [
  '장비확인',
  '부화/채란 관리',
  '입식작업',
  '사료배합관리',
  '생육관리',
  '채집 및 가공',
] as const;
export type CheckListType = (typeof CHECK_LIST_TYPES)[number];

export type ChecklistDevice = {
  id: number;
  farmNumber: number;
  date: string;
  temperature: number;
  humidity: number;
  co2: number;
  voc: number;
  pm25: number;
  device1: number;
  device2: number;
  device3: number;
  device4: number;
  device5: number;
  device6: number;
  device7: number;
  device8: number;
  device9: number;
  device10: number;
  device11: number;
  device12: number;
};

export type ChecklistEgg = {
  id: number;
  farmNumber: number;
  date: string;
  isCheck: number;
  trayCount: number;
  check1: number;
  check2: number;
  check3: number;
  check4: number;
  check5: number;
  check6: number;
  check7: number;
};

export type ChecklistFeed = {
  id: number;
  farmNumber: number;
  date: string;
  feedCount: number;
  ingredient1: number;
  ingredient2: number;
  ingredient3: number;
  ingredient4: number;
  check1: number;
  check2: number;
  check3: number;
  check4: number;
  check5: number;
};

export type ChecklistHarvest = {
  id: number;
  farmNumber: number;
  date: string;
  check1: number;
  check2: number;
  check3: number;
  check4: number;
  check5: number;
  check6: number;
  check7: number;
  check8: number;
  check9: number;
};

export type ChecklistHatching = {
  id: number;
  farmNumber: number;
  date: string;
  check1: number;
  check2: number;
  check3: number;
  check4: number;
  check5: number;
  check6: number;
  check7: number;
  check8: number;
};

export type ChecklistGrowth = {
  id: number;
  farmNumber: number;
  date: string;
  cellType: CellType;
  check1: number;
  check2: number;
  check3: number;
  check4: number;
  check5: number;
  check6: number;
  check7: number;
  check8: number;
  check9: number;
  check10: number;
  check11: number;
  check12: number;
  check13: number;
  check14: number;
  check15: number;
  check16: number;
};

export type ChecklistExist = {
  device: boolean;
  egg: boolean;
  hatching: boolean;
  feed: boolean;
  growth: boolean;
  harvest: boolean;
};

export const FARM_RECORDS_TASK_TYPES = [1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9];
export type RecordTaskType = (typeof FARM_RECORDS_TASK_TYPES)[number];
/**
 * 1. 채집
2. 채란
3. 입식
4. checklist_device
5. checklist_egg
6. checklist_hatching
7. checklist_feed
8. checklist_growth
9. checklist_harvest
 */

export type FarmRecords = {
  taskType: RecordTaskType;
  dateTime: string;
};
//채란, 채집, 입식과 같이 cell 번호가 필요한 작업
export type FarmRecordsCell = {
  farmNumber: number;
  lineNumber: number;
  blockNumber: number;
  floorNumber: number;
  taskType: number;
};
