import { Access, TemperatureByDay, TemperatureByHour } from 'types/types';
import customAxios from './customAxios';
import { getApiBaseUrl } from 'api';

type TemperatureStatisticsResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  temperature_statistics?: TemperatureByHour[];
};

type RequestTemperatureStatisticsWeekResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  temperature_statistics?: TemperatureByDay[];
};
/**일간 온습도 조회 */
type TemperatureStatisticsPayload = {
  farmNumber: number;
  controllerNumber: number;
};
export async function requestTemperatureStatistics({
  payload,
  access,
}: {
  payload: TemperatureStatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response = await customAxios.post<TemperatureStatisticsResponse>(
      `${getApiBaseUrl(access)}/web/temperature_statistics.php`,
      requestBody,
    );
    return response.data.temperature_statistics;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}
/**주간 온습도 조회 */
export async function requestTemperatureStatisticsWeek({
  payload,
  access,
}: {
  payload: TemperatureStatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response =
      await customAxios.post<RequestTemperatureStatisticsWeekResponse>(
        `${getApiBaseUrl(access)}/web/temperature_statistics_week.php`,
        requestBody,
      );
    return response.data.temperature_statistics;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}
