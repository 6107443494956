import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { requestFarm, requestUpdateFarm } from 'api/farm';
import useFarmStore from 'store/farmStore';

export const FARM_QUERY_KEY = 'farm';

// 장치 데이터를 쿼리하는 커스텀 훅
export const useFarmQuery = () => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  return useQuery({
    queryKey: [FARM_QUERY_KEY, farmNumber, access],
    queryFn: async () => await requestFarm({ payload: { farmNumber }, access }),
    staleTime: 20 * 60 * 1000, //20분
    cacheTime: 60 * 60 * 1000, //1시간
  });
};

// 장치 데이터를 쿼리하는 커스텀 훅
export const useFarmsQuery = () => {
  const { farmNumbers, accessMap } = useFarmStore();
  return useQuery({
    queryKey: [FARM_QUERY_KEY, farmNumbers],
    queryFn: async () => {
      // Promise.all을 사용하여 병렬로 여러 개의 요청을 처리
      const responses = await Promise.all(
        farmNumbers.map((farmNumber) => {
          const currentAccess = accessMap?.[farmNumber] ?? 0;
          return requestFarm({
            payload: { farmNumber },
            access: currentAccess,
          });
        }),
      );
      // 모든 응답을 배열로 반환
      return responses;
    },
  });
};
export const useUpdateFarmMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestUpdateFarm,
    onSuccess: () => {
      queryClient.refetchQueries([FARM_QUERY_KEY]);
    },
  });
};
