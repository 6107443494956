import { Point, ResponsiveLine, Serie } from '@nivo/line';
import clsx from 'clsx';
import { DAYS_OF_WEEK } from 'constants/weekdays';
import useTranslate from 'hooks/useTranslate';
import { useMemo, useState } from 'react';

interface GraphProps {
  type: 'temperature' | 'humidity' | 'co2';
  data: Serie[];
  period: 'day' | 'week';
}

export default function HeaderGraph(props: GraphProps) {
  const { type, data, period } = props;
  const t = useTranslate();
  const unit = useMemo(() => {
    switch (type) {
      case 'temperature':
        return '℃';
      case 'humidity':
        return '%';
      case 'co2':
        return 'ppm';
      default:
        return '';
    }
  }, [type]);

  const getDayString = (time: number, period: 'day' | 'week') => {
    if (period === 'day') return `${time}${t('시')}`;
    else {
      try {
        return t(`${DAYS_OF_WEEK[time - 1]}`);
      } catch {
        return '';
      }
    }
  };
  const [activePoint, setActivePoint] = useState<Point | null>(null);

  return (
    <div className="relative w-full h-full">
      {activePoint && (
        <div className="absolute z-40 flexCenter right-5 bottom-2">
          <div
            className={clsx(
              'relative text-sm font-bold transition-transform duration-500',
            )}
          >
            <div className="flex flex-col p-1 text-xs bg-white opacity-80 rounded-10 shadow-main">
              <span>{getDayString(Number(activePoint.data.x), period)}</span>
              <span>
                {activePoint.data.y.toString()}
                {unit}
              </span>
            </div>
          </div>
        </div>
      )}
      <ResponsiveLine
        onMouseMove={(point) => setActivePoint(point)}
        onMouseLeave={() => setActivePoint(null)}
        crosshairType="bottom"
        curve="linear"
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 0 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        tooltip={() => null}
        lineWidth={1}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        pointSize={2}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[]}
        isInteractive={true}
        colors={[getColor(type)]}
        defs={[
          {
            id: 'temperature',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#ff0000' },
              { offset: 20, color: '#ffffff', opacity: 0.8 },
            ],
          },
          {
            id: 'humidity',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#0025fa' },
              { offset: 20, color: '#ffffff', opacity: 0.8 },
            ],
          },
          {
            id: 'co2',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: '#17A34B' },
              { offset: 300, color: '#ffffff', opacity: 0.8 },
            ],
          },
        ]}
        fill={[{ match: { id: type }, id: type }]}
        enableArea={true}
        areaOpacity={0.1}
      />
    </div>
  );
}

const getColor = (type: 'temperature' | 'humidity' | 'co2' | undefined) => {
  switch (type) {
    case 'temperature':
      return '#f2a7a7';
    case 'humidity':
      return '#a6b2f3';
    case 'co2':
      return '#90d5a9';
    default:
      return '#AEAEAE';
  }
};
