import { Access, Co2ByDay, Co2ByHour } from 'types/types';
import customAxios from './customAxios';
import { getApiBaseUrl } from 'api';

type Co2StatisticsResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  co2_statistics?: Co2ByHour[];
};

type Co2StatisticsWeekResponse = {
  isSuccess: boolean;
  code: number;
  message: string;
  co2_statistics?: Co2ByDay[];
};

type Co2StatisticsPayload = {
  farmNumber: number;
  controllerNumber: number;
};
export async function requestCo2Statistics({
  payload,
  access,
}: {
  payload: Co2StatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response = await customAxios.post<Co2StatisticsResponse>(
      `${getApiBaseUrl(access)}/web/co2_statistics.php`,
      requestBody,
    );
    return response.data.co2_statistics;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}

export async function requestCo2StatisticsWeek({
  payload,
  access,
}: {
  payload: Co2StatisticsPayload;
  access: Access;
}) {
  const { farmNumber, controllerNumber } = payload;
  const requestBody = new FormData();
  requestBody.append('farm_number', farmNumber.toString());
  requestBody.append('controller_number', controllerNumber.toString());

  try {
    const response = await customAxios.post<Co2StatisticsWeekResponse>(
      `${getApiBaseUrl(access)}/web/co2_statistics_week.php`,
      requestBody,
    );
    return response.data.co2_statistics;
  } catch (error) {
    console.error('에러가 발생하였습니다. 다시 확인해 주십시오.');
    throw error;
  }
}
