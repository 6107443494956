import { UserType } from 'types/types';

export const TYPE_NONE_MASK = 0;
export const TYPE_HUB_MASK = 1;
export const TYPE_RACK_MASK = 2;
export const TYPE_RAIL_MASK = 4;

// 반달허브 타입 판별 함수
export const isHubType = (userType: UserType) => {
  const mask = TYPE_HUB_MASK; // 0b001 또는 2^0, 오른쪽에서 세 번째 비트
  return (userType & mask) === mask;
};
// 사육대 타입 판별함수
export const isRackType = (userType: UserType) => {
  const mask = TYPE_RACK_MASK; // 0b10 또는 2^1, 오른쪽에서 두 번째 비트
  return (userType & mask) === mask;
};
// 사육레일 타입 판별함수
export const isRailType = (userType: UserType) => {
  const mask = TYPE_RAIL_MASK; // 0b100 또는 2^2, 오른쪽에서 세 번째 비트
  return (userType & mask) === mask;
};
export const hasNoFarmType = (userType: UserType) => {
  return !isRailType(userType) && !isRackType(userType) && !isHubType(userType);
};
// 현재 농장의 userType과 mask를 비교하여 sidebar 목룍을 보여줄지 판별하는 함수
export const isEnabledForUserType = (userType: UserType, mask: number) => {
  // mask가 0인 경우, userType에 상관없이 보여줌
  if (mask === 0) return true;
  return (userType & mask) === mask;
};
