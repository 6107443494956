import { Locale } from 'types/types';
import useLocaleStore from 'store/localeStore';
import engDict from 'i18n/eng/dict.json';
import engLandingDict from 'i18n/eng/dictLanding.json';
import engGlobalDict from 'i18n/eng/dictGlobal.json';
import engWormDict from 'i18n/eng/dict_worm.json';
import engDiaryDict from 'i18n/eng/dict_diary.json';

import krDict from 'i18n/kr/dict.json';
import krLandingDict from 'i18n/kr/dictLanding.json';
import krGlobalDict from 'i18n/kr/dictGlobal.json';
import krWormDict from 'i18n/kr/dict_worm.json';
import krDiaryDict from 'i18n/kr/dict_diary.json';

import vnmDict from 'i18n/vnm/dict.json';
import vnmLandingDict from 'i18n/vnm/dictLanding.json';
import vnmGlobalDict from 'i18n/vnm/dictGlobal.json';
import vnmWormDict from 'i18n/vnm/dict_worm.json';
import vnmDiaryDict from 'i18n/vnm/dict_diary.json';

export interface LocaleDict {
  [key: string]: string;
}
export type Context = 'default' | 'landing' | 'global' | 'worm';
const dict: {
  [key in Locale]: {
    default: LocaleDict;
    landing: LocaleDict;
    global: LocaleDict;
    worm: LocaleDict;
  };
} = {
  KR: {
    default: { ...krDict, ...krDiaryDict },
    landing: krLandingDict,
    global: krGlobalDict,
    worm: krWormDict,
  },
  ENG: {
    default: { ...engDict, ...engDiaryDict },
    landing: engLandingDict,
    global: engGlobalDict,
    worm: engWormDict,
  },
  VNM: {
    default: { ...vnmDict, ...vnmDiaryDict },
    landing: vnmLandingDict,
    global: vnmGlobalDict,
    worm: vnmWormDict,
  },
};

function useTranslate(context: Context = 'default') {
  const { locale } = useLocaleStore();

  const translate = (key: string, variables?: { [key: string]: any }) => {
    let translation = dict[locale][context][key];
    if (dict[locale][context][key] === undefined) translation = key;
    if (variables) {
      for (const variable in variables) {
        const replaceWith = variables[variable];
        translation = translation.replace(`{${variable}}`, replaceWith);
      }
    }

    return translation;
  };

  return translate;
}
export default useTranslate;
