import clsx from 'clsx';
import useTranslate from 'hooks/useTranslate';
import React from 'react';
import { cn } from 'utils/cn';

type PeriodChartSelectorProps = {
  className?: string;
  periodType: 'day' | 'week';
  setPeriodType: React.Dispatch<React.SetStateAction<'day' | 'week'>>;
};
const PeriodChartSelector: React.FC<PeriodChartSelectorProps> = ({
  periodType,
  setPeriodType,
  className,
}) => {
  const t = useTranslate();
  const togglePeriodType = () => {
    if (periodType === 'day') setPeriodType('week');
    else setPeriodType('day');
  };
  const containerClass = clsx(
    'flex rounded-20 transition-all shadow-main whitespace-nowrap',
    'relative',
  );
  const isSelected = (type: 'day' | 'week') => periodType === type;
  const beforeClass = clsx(
    'before:absolute before:w-1/2 before:h-10 before:bg-mainGreen before:rounded-full before:transition-all before:contents-[""] ',
    { '': periodType === 'day' },
    { 'before:translate-x-full': periodType === 'week' },
  );

  return (
    <div
      className={cn(containerClass, beforeClass, className)}
      onClick={togglePeriodType}
    >
      <div
        className={clsx(periodTypeClass, {
          'text-white font-bold': isSelected('day'),
        })}
      >
        {t('일간')}
      </div>
      <div
        className={clsx(periodTypeClass, {
          'text-white font-bold': isSelected('week'),
        })}
      >
        {t('주간')}
      </div>
    </div>
  );
};
export default PeriodChartSelector;
// Stype ClassName
const periodTypeClass = clsx(
  'px-[15px] py-[10px] rounded-40 z-[1]',
  'text-center text-subText transition-colors',
);
