import React from 'react';
import clsx from 'clsx';

const SkeletonChart = () => {
  const skeletonClass = clsx(
    // Add your skeleton styles here
    'bg-gray-400 animate-pulse rounded-md w-[6vw] min-w-[100px]',
  );

  return <div className={skeletonClass} />;
};

export default SkeletonChart;
