import clsx from 'clsx';
import CustomSelect from 'components/common/CustomSelect';
import useTranslate from 'hooks/useTranslate';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';

export default function ControllerSelect() {
  const {
    setCurrentControllerNumber,
    controllerListMap,
    currentControllerNumber,
  } = useControllerStore();
  const { currentFarmNumber: farmNumber } = useFarmStore();
  const t = useTranslate();
  const controllerList = controllerListMap[farmNumber];
  const ControllerOptions = controllerList.map((contollerNumber) => ({
    value: contollerNumber,
    label: `${t('controller')} ${contollerNumber}`,
  }));
  const onChange = (value: number | string) => {
    const selectedControllerNumber = Number(value);
    controllerList.includes(selectedControllerNumber) &&
      setCurrentControllerNumber(selectedControllerNumber);
  };
  const className = clsx(
    'py-3 px-4',
    'xs:py-[15px] xs:px-5 xs:gap-[35px]',
    'max-w-[166px] rounded-full borderSubline',
    'text-xs xs:text-sm md:text-base',
  );
  return (
    <CustomSelect
      className={className}
      options={ControllerOptions}
      defaultValue={currentControllerNumber}
      onChange={onChange}
      zIndex={1000}
    />
  );
}
