import { useController } from 'hooks/controller/useController';
import { useState } from 'react';

import clsx from 'clsx';
import HeaderGraphContainer from './HeaderGraphContainer';
import SkeletonChart from 'components/fallback/SkeletonChart';
import useTranslate from 'hooks/useTranslate';
import PeriodChartSelector from './PeriodChartSelector';
import AsyncBoundary from 'components/common/AsyncBoundary';

export default function StatisticArea() {
  const t = useTranslate();
  const { controller } = useController();
  const [periodType, setPeriodType] = useState<'day' | 'week'>('day');
  const wrapperClass = clsx('flex flex-col', 'px-30px py-3.5', 'px-1');

  return (
    <div className="relative flex items-center gap-5">
      <div className="sticky left-0 flex flex-col gap-5px ">
        <p className="font-bold whitespace-nowrap">{t('현재 환경 상태값')}</p>
        <div className="flex gap-1 text-sm">
          <PeriodChartSelector
            periodType={periodType}
            setPeriodType={setPeriodType}
          />
        </div>
      </div>

      <div
        className={clsx(
          'flex items-center gap-x-2.5 px-1 py-1 overflow-x-auto w-[calc(100vw-770px)]',
        )}
      >
        <div className="flex flex-shrink-0 overflow-hidden rounded-full shadow-main">
          <div className={`${wrapperClass}`}>
            <span className="text-sm text-subText">{t('온도')}</span>
            <span className="text-lg font-bold lg:text-xl">
              <span className="mr-0.5">{controller?.temperature}</span>℃
            </span>
          </div>
          <div className="w-[140px] ">
            <AsyncBoundary
              ErrorFallback={() => <SkeletonChart />}
              SuspenseFallback={<SkeletonChart />}
            >
              <HeaderGraphContainer
                type={'temperature'}
                periodType={periodType}
              />
            </AsyncBoundary>
          </div>
        </div>
        <div className="flex flex-shrink-0 overflow-hidden rounded-full shadow-main">
          <div className={`${wrapperClass}`}>
            <span className="text-sm text-subText">{t('습도')}</span>
            <span className="text-lg font-bold lg:text-xl">
              <span className="mr-0.5">{controller?.humidity}</span>%
            </span>
          </div>
          <div className="block w-[140px] ">
            <AsyncBoundary
              ErrorFallback={() => <SkeletonChart />}
              SuspenseFallback={<SkeletonChart />}
            >
              <HeaderGraphContainer type={'humidity'} periodType={periodType} />
            </AsyncBoundary>
          </div>
        </div>
        <div className="flex flex-shrink-0 overflow-hidden rounded-full shadow-main">
          <div className={`${wrapperClass}`}>
            <span className="text-sm text-subText">{t('이산화탄소')}</span>
            <span className="text-lg font-bold lg:text-xl">
              <span className="mr-0.5">{controller?.co2}</span>
              ppm
            </span>
          </div>
          <div className="block w-[140px] ">
            <AsyncBoundary
              ErrorFallback={() => <SkeletonChart />}
              SuspenseFallback={<SkeletonChart />}
            >
              <HeaderGraphContainer type={'co2'} periodType={periodType} />
            </AsyncBoundary>
          </div>
        </div>
        <div className="w-[90px] flexCenter self-stretch shadow-main rounded-full flex-shrink-0">
          <div className="flex flex-col items-center l  py-2.5 px-5">
            <span className="text-sm text-subText">VOC</span>
            <span className="text-lg font-bold lg:text-xl">
              {controller?.voc}
            </span>
          </div>
        </div>
        <div className="w-[90px] self-stretch rounded-full flexCenter shadow-main flex-shrink-0">
          <div className="flex flex-col items-center l  py-2.5 px-5">
            <span className="text-sm text-subText">pm2.5</span>
            <span className="text-lg font-bold lg:text-xl">
              <span className="mr-0.5">{controller?.pm25}</span>㎍/m³
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
