/* eslint-disable prettier/prettier */
import {
  useCo2StatisticQuery,
  useCo2StatisticWeekQuery,
} from 'hooks/reactQuery/co2Query';
import {
  useHumidityStatisticQuery,
  useHumidityStatisticWeekQuery,
} from 'hooks/reactQuery/humidityQuery';
import {
  useTemperatureStatisticQuery,
  useTemperatureStatisticWeekQuery,
} from 'hooks/reactQuery/temperatureQuery';
import { useMemo } from 'react';
import useControllerStore from 'store/controllerStore';
import {
  Co2ByDay,
  Co2ByHour,
  HumidityByDay,
  HumidityByHour,
  TemperatureByDay,
  TemperatureByHour,
} from 'types/types';
import HeaderGraph from './HeaderGraph';
import { Serie } from '@nivo/line';
import IcLogo from 'components/icon/IcLogo';

type HeaderGraphContainerProps = {
  type: 'temperature' | 'humidity' | 'co2';
  periodType: 'day' | 'week';
};

const getStatisticQuery = (
  type: 'temperature' | 'humidity' | 'co2',
  periodType: 'day' | 'week',
) => {
  if (type === 'temperature')
    return periodType === 'day'
      ? useTemperatureStatisticQuery
      : useTemperatureStatisticWeekQuery;
  if (type === 'humidity')
    return periodType === 'day'
      ? useHumidityStatisticQuery
      : useHumidityStatisticWeekQuery;
  /*type==='co2' */ else
    return periodType === 'day'
      ? useCo2StatisticQuery
      : useCo2StatisticWeekQuery;
};

export default function HeaderGraphContainer(props: HeaderGraphContainerProps) {
  const { type, periodType } = props;
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  const { data: statistics } = getStatisticQuery(
    type,
    periodType,
  )(controllerNumber);
  const averages = useMemo(() => {
    if (!statistics) return [];
    if (type === 'temperature' && periodType === 'day')
      return statistics.map(
        (item) => (item as TemperatureByHour).avg_temperature,
      );
    if (type === 'temperature' && periodType === 'week')
      return statistics.map(
        (item) => (item as TemperatureByDay).avg_temperature,
      );
    if (type === 'humidity' && periodType === 'day')
      return statistics.map((item) => (item as HumidityByHour).avg_humidity);
    if (type === 'humidity' && periodType === 'week')
      return statistics.map((item) => (item as HumidityByDay).avg_humidity);
    if (type === 'co2' && periodType === 'day')
      return statistics.map((item) => (item as Co2ByHour).avg_co2);
    if (type === 'co2' && periodType === 'week')
      return statistics.map((item) => (item as Co2ByDay).avg_co2);
    return [];
  }, [periodType, statistics, type]);
  const labels = useMemo(() => {
    if (!statistics) return null;
    if (periodType === 'day')
      return statistics.map(
        (item) => (item as TemperatureByHour | HumidityByHour | Co2ByHour).time,
      );
    if (periodType === 'week')
      return statistics.map(
        (item) => (item as TemperatureByDay | HumidityByDay | Co2ByDay).day,
      );
  }, [periodType, statistics]);
  const data: Serie[] =
    averages && labels
      ? [
          {
            id: type,
            data: averages.map((average, index) => ({
              x: labels[index] !== undefined ? labels[index] : index,
              y: average,
            })),
          },
        ]
      : [];
  if (statistics?.length === 0)
    return (
      <div className="w-full h-full flexCenter">
        <IcLogo width={24} height={24} />
      </div>
    );
  return <HeaderGraph type={type} data={data} period={periodType} />;
}
